import React, { useContext, useEffect, useRef, useState } from "react";
import { isEmpty, assignIn, cloneDeep, find, filter, forIn } from "lodash";
import { Tag, Input, Tooltip, Checkbox } from "antd";
import { Button } from "antd";
import CustomCheckbox from "./CustomCheckbox";
import "./style.scss";
import { SearchOutlined } from "@ant-design/icons";

const MultiCheckboxSelection = (props) => {
  const {
    data,
    onCheckItem,
    onCheckAll,
    isChecked,
    showSelectAll,
    className = "custom-checkbox-item",
  } = props;
  const [checkboxData, setData] = useState([]);
  const [oData, setOData] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    setData(cloneDeep(data));
    setOData(cloneDeep(data));
  }, [data]);

  const handleChangeItem = (e) => {
    checkboxData.forEach((item) => {
      item.isChecked = e.target.checked;
      item.className = "custom-checkbox-item";
    });
    onCheckAll(e.target.checked);
    document.getElementById("checkAll").checked = e.target.checked;
  };

  function filterItems(arr, query) {
    return arr.filter(function (el) {
      return el.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }

  const search = function (event) {
    setValue(event.target.value);
    let arrData = filterItems(oData, event.target.value);
    //if (arrData.length <= checkboxData.length && arrData.length>0)
    setData(arrData);
    //else setData(checkboxData);
  };

  return (
    <div className="MultiCheckboxSelection-wp">
      <Input
        prefix={
          <SearchOutlined
            style={{
              fontSize: 13,
              paddingTop: 7,
              paddingBottom: 7,
            }}
          />
        }
        style={{ borderRadius: 7 }}
        name="search"
        onChange={search}
        value={value}
        placeholder="Enter to search"
      />
      <div style={{ marginBottom: 10 }}></div>
      {showSelectAll && (
        <div className="customCheckbox-wp">
          <label class="custom-checkbox-item">
            <input
              type="checkbox"
              key={"selectAll"}
              name="checkAll"
              id="checkAll"
              onChange={handleChangeItem}
            />
            <span />
            <div>Select All</div>
          </label>
        </div>
      )}
      {!isEmpty(checkboxData) &&
        checkboxData.map((cItem, index) => (
          <CustomCheckbox
            checked={cItem.isChecked}
            key={cItem.id}
            data={cItem}
            onCheck={onCheckItem}
            className={cItem.className}
          />
        ))}
    </div>
  );
};

MultiCheckboxSelection.defaultProps = {
  showSelectAll: true,
};

export default MultiCheckboxSelection;
